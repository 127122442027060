* {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}


.font-nycd {
  /* nothing you could do */
  font-family: 'Nothing You Could Do';
}


.montserrat-alternates-thin {
  /* montserrat - montserrat alternate */
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.montserrat-alternates-thin-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.montserrat-alternates-extralight {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.montserrat-alternates-extralight-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.montserrat-alternates-light {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.montserrat-alternates-light-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.montserrat-alternates-regular {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-alternates-regular-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-alternates-medium {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.montserrat-alternates-medium-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.montserrat-alternates-semibold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.montserrat-alternates-semibold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.montserrat-alternates-bold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-alternates-bold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.montserrat-alternates-extrabold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.montserrat-alternates-extrabold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.montserrat-alternates-black {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.montserrat-alternates-black-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: italic;
}






.roboto-thin {
  /* roboto */
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}