:root {
  --aqua: #14a7ad;
  --blue: #17365d;
}

.noLinkStyle {
  text-decoration: none !important;
  color: #111 !important;
}

.navBarFocus {
  border-bottom: 3px solid #f8f9fa;
}

/* Max Width */
.myMaxW1700 {
  max-width: 1700px;
}

.myMaxWidth1600 {
  max-width: 1600px;
}

.myMaxW1000 {
  max-width: 1000px;
}

.myMaxW700 {
  max-width: 700px;
}

.myMaxW650 {
  max-width: 650px;
}

.myMaxW500 {
  max-width: 500px;
}

.myMaxW450 {
  max-width: 450px;
}

.myMaxW300 {
  max-width: 300px;
}

/* Background Color */
.myBgAqua {
  background-color: var(--aqua) !important;
}

.myBgBlue {
  background-color: var(--blue);
}

.myBgAcqua {
  background-color: #14a7ad !important;
}

.myBgFucsia {
  background-color: #e82283 !important;
}

.myBgDarkgray {
  background-color: #454444 !important;
}

.myBgImgCover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.myBgTransparent60 {
  background-color: #101010de;
}

/* indicatore tondo numeri riviste */
.myNumberCircle {
  height: 160px;
  width: 160px;
  border-radius: 80px;
}

.myFont15rem {
  font-size: 1.5rem;
}

a:hover {
  color: rgb(0, 0, 185) !important;
}